import React from "react"
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const flagStyle={
    "height": 50,
    "widht": 50,
    "marginLeft": 10
}
const LanguageSwitch= () =>{
    const {languages, originalPath} = useI18next();
    return (
        <Container fluid>
            <Row>
                <Col style={{"textAlign": "center"}}>
                <Link to={originalPath} language="es"><img src="https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg" style={flagStyle}></img></Link>
                <Link to={originalPath} language="en"><img src="https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg" style={flagStyle}></img></Link>
                <Link to={originalPath} language="fr"><img src="https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg" style={flagStyle} ></img></Link>
                </Col>
            </Row>
        </Container>
    )
}
export default LanguageSwitch