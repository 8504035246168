import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import React from "react"
const NavigationBar =() =>{
    const {t}= useTranslation()
    return(
    <Navbar bg="primary" variant="dark">
        <Navbar.Brand>{t("title")}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            <Nav.Link ><Link to="/">{t("home")}</Link></Nav.Link>
            <Nav.Link ><Link to="/about">{t("about")}</Link></Nav.Link>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
)}

export default NavigationBar