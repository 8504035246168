import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import React from "react"
const Footer =() =>{
    const {t}= useTranslation()
    return(
        <div style={{ height:"100%","backgroundColor": "#593196", paddingTop: "1em", paddingBottom: "7em", marginTop: "4em", color:"white", textAlign: "center"}}>
            {t("description")}<br/>
            {t("dependencies")}
        </div>
    )
}
export default Footer